import React from 'react';
import Register from "../assets/Registrieren_bei_binance_um_bitcoin_zu_kaufen.jpg";
import Verify from '../assets/Verifizieren_binance.jpg';
import '../Anleitung.css'; // Importiere die CSS-Datei

const ErstelleEinKonto = () => {
  return (
    <div className="min-h-screen py-8">
      <div className="container mx-auto px-4">
        <article className="bg-white p-8 shadow-lg">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Wie man einfach und sicher Bitcoin online kauft</h1>
          <p className="text-gray-600 mb-6">Erfahren Sie in dieser Schritt-für-Schritt-Anleitung, wie Sie ganz einfach Ihren ersten Bitcoin online via Sofortüberweisung kaufen können. Ideal für Anfänger!</p>
          
          <div className="bg-gray-900 w-82 p-4 mb-6 border-l-4 border-blue-500">
            <h2 className="text-2xl font-semibold text-white mb-2">Inhaltsverzeichnis</h2>
            <ol className="list-decimal list-inside text-blue-500">
              <li className="p-2">
                <a href="#schritt1" className="text-blue-500 underline">Erstellen Sie ein Konto auf Binance</a>
              </li>
              <li className="p-2">
                <a href="#schritt2" className="text-blue-500 underline">Verifizieren Sie Ihr Konto mit Hilfe Ihres Personalausweis (KYC)</a>
              </li>
              <li className="p-2"> 
                <a href="#schritt3" className="text-blue-500 underline">Fügen Sie Geld über Sofortüberweisung oder Kreditkarte zu Ihrem Binance-Konto hinzu</a>
              </li>
              <li className="p-2">
                <a href="#schritt4" className="text-blue-500 underline">Kaufen Sie online Bitcoin auf Binance</a>
              </li>
            </ol>
          </div>

          <div className="space-y-12">
            <section id="schritt1">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Erstellen Sie ein Konto auf Binance</h2>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Folgen Sie diesem <a href="https://accounts.binance.com/register?ref=YSY8VOEH" className="text-blue-500 underline">Link</a> auf Binance, um <b>10% Rabatt auf die Kaufgebühren</b> zu erhalten.</li>
                <li>Sie können sich sowohl mit Ihrem Google-Account, Apple-Account als auch mit Ihrer E-Mail registrieren.</li>
                <li>Geben Sie Ihre E-Mail-Adresse und ein sicheres Passwort ein.</li>
                <li>Bestätigen Sie Ihre E-Mail-Adresse, indem Sie dem Link in der Bestätigungs-E-Mail von Binance folgen.</li>
                <li>Schließen Sie die Registrierung ab, indem Sie alle erforderlichen Informationen eingeben.</li>
              </ul>
              <div className="mt-4">
                <img src={Register} alt="Konto erstellen" className="responsive-img" />
                <p className="text-sm text-center mt-2 text-gray-600">Zur Kontoerstellung stehen Ihnen sowohl ihr Google-Account als auch Ihr Apple-Account zur Verfügung.</p>
              </div>
            </section>

            <section id="schritt2">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Verifizieren Sie Ihr Konto</h2>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Melden Sie sich bei Ihrem Binance-Konto an.</li>
                <li>Klicken Sie oben rechts auf Ihr Profilbild und wählen Sie "Identitätsverifizierung".</li>
                <li>Folgen Sie den Anweisungen, um Ihre Identität zu überprüfen. Sie werden wahrscheinlich Fotos oder Scans Ihres Ausweises oder Reisepasses sowie ein Selfie hochladen müssen.</li>
                <li>Warten Sie auf die Überprüfung Ihrer Dokumente. Dies kann einige Zeit in Anspruch nehmen, aber Sie werden per E-Mail über den Fortschritt informiert.</li>
              </ul>
              <div className="mt-4">
                <img src={Verify} alt="Konto verifizieren" className="responsive-img" />
                <p className="text-sm text-center mt-2 text-gray-600">Beispielbild: Identitätsverifizierung auf Binance.</p>
              </div>
            </section>

            <section id="schritt3">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Fügen Sie Geld zu Ihrem Binance-Konto hinzu</h2>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Nachdem Ihr Konto verifiziert wurde, melden Sie sich an und klicken Sie auf "Einzahlungen" in der oberen Menüleiste.</li>
                <li>Wählen Sie die gewünschte Einzahlungsmethode, zum Beispiel Banküberweisung oder Kreditkarte, und folgen Sie den Anweisungen, um das Geld auf Ihr Binance-Konto zu überweisen.</li>
                <li>Warten Sie, bis das Geld auf Ihrem Binance-Konto eingegangen ist. Dies kann je nach Einzahlungsmethode variieren.</li>
              </ul>
              <div className="mt-4">
                <img src="https://via.placeholder.com/600x400?text=Geld+einzahlen" alt="Geld einzahlen" className="responsive-img" />
                <p className="text-sm text-center mt-2 text-gray-600">Beispielbild: Geld auf das Binance-Konto einzahlen.</p>
              </div>
            </section>

            <section id="schritt4">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Kaufen Sie Bitcoin auf Binance</h2>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Melden Sie sich bei Ihrem Binance-Konto an.</li>
                <li>Klicken Sie oben in der Menüleiste auf "Handel" und wählen Sie "Krypto kaufen".</li>
                <li>Wählen Sie Bitcoin (BTC) als die gewünschte Kryptowährung aus.</li>
                <li>Geben Sie den Betrag ein, den Sie kaufen möchten, oder wählen Sie einen der vorgeschlagenen Beträge aus.</li>
                <li>Überprüfen Sie die Transaktionsdetails und bestätigen Sie den Kauf.</li>
              </ul>
              <div className="mt-4">
                <img src="https://via.placeholder.com/600x400?text=Bitcoin+kaufen" alt="Bitcoin kaufen" className="responsive-img" />
                <p className="text-sm text-center mt-2 text-gray-600">Beispielbild: Bitcoin auf Binance kaufen.</p>
              </div>
            </section>
          </div>

          <p className="font-bold mt-6 text-center text-gray-800">Gratulation! Sie haben erfolgreich Bitcoin auf Binance gekauft. Die gekauften Bitcoin werden in Ihrem Binance-Wallet angezeigt.</p>
          <br />
          <p className="text-center text-gray-600">Bitte beachten Sie, dass der genaue Ablauf je nach Benutzeroberfläche von Binance variieren kann. Stellen Sie sicher, dass Sie sich mit den aktuellen Funktionen und Richtlinien von Binance vertraut machen, da diese sich im Laufe der Zeit ändern können.</p>
        </article>
      </div>
    </div>
  );
};

export default ErstelleEinKonto;
