import React from 'react';
import '../Anleitung.css'; // Importiere die CSS-Datei

const Disclaimer = () => {
  return (
    <div className="min-h-screen py-8">
      <div className="container mx-auto px-4">
        <article className="bg-white p-8 shadow-lg">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Disclaimer</h1>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Keine Anlageberatung</h2>
            <p className="text-gray-600">
              Ich schreibe auf diesem Blog über meine persönlichen Erfahrungen als Privatperson und Privatanleger.
              Alle zur Verfügung gestellten Informationen (Kommentare, Hinweise, Ratschläge etc.) dienen allein der finanziellen Bildung und als Denkanstöße. Sie sind nicht als persönliche Anlageberatung zu verstehen. Ich übernehme keinerlei Haftung für Anlageentscheidungen, die ihr aufgrund der hier präsentierten Informationen trefft.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Risikohinweis</h2>
            <p className="text-gray-600">
              Jede Investition in Wertpapiere birgt Risiken, den Verlust des eingesetzten Kapitals eingeschlossen. Finanzmärkte unterliegen nicht vorhersehbaren Volatilitäten, die den Wert deines Vermögens reduzieren und sogar zum Totalverlust führen können. Es gibt keine Garantie dafür, dass du deine finanziellen Ziele erreichst oder sich dein Vermögen wie erwartet/gewünscht entwickelt.
              <br /><br />
              Eine Investition in Fonds/ETFs unterliegt bestimmten Risiken. Als mögliche Risiken kommen Kursschwankungs- und Kursverlustrisiken, Bonitäts-/Emittenrisiken, Wechselkurs- oder Zinsänderungsrisiken, Kontrahentenrisiko, Verlustrisiko bei Rohstoff-ETFs und/oder Totalverlustrisiko in Betracht. Diese Risiken können einzeln und kumuliert auftreten.
              <br /><br />
              Besonders bei Investitionen in Bitcoin und andere Kryptowährungen können hohe Volatilitäten auftreten. Die Preise von Bitcoin können stark schwanken, was zu erheblichen Gewinnen, aber auch zu großen Verlusten führen kann. Informiere dich gründlich und erwäge eine professionelle Beratung, bevor du in Kryptowährungen investierst.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Nutzungsbedingungen</h2>
            <p className="text-gray-600">
              Die Informationen und Dokumente auf den Internet-Seiten des Inhabers der Seite richten sich ausschließlich an Interessenten, die ihren Wohnsitz in der Bundesrepublik Deutschland haben. Die Dokumente/Informationen sind daher ausschließlich in deutscher Sprache gefasst. Sie richten sich nicht an Personen mit Wohn- und/oder Gesellschaftssitz und/oder Niederlassung im Ausland, insbesondere in den Vereinigten Staaten von Amerika, Kanada, Großbritannien oder Japan. Die Dokumente/Informationen dürfen in anderen Rechtsordnungen nur in Einklang mit den dort geltenden Rechtsvorschriften verteilt werden, und Personen, die in den Besitz dieser Informationen und Materialien gelangen, haben sich über die dort geltenden Rechtsvorschriften zu informieren und diese zu befolgen.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Nutzungsrecht an den Inhalten</h2>
            <p className="text-gray-600">
              Die Web-Seiten, ihre Programmierung, Inhalte, Gestaltung und Struktur unterliegen urheber-, marken- und wettbewerbsrechtlichen Schutzrechten. Urheberrechtshinweise und Markenbezeichnungen dürfen weder verändert noch beseitigt werden. Eine Vervielfältigung, Verbreitung, Bereithaltung zum Abruf oder Online-Zugänglichmachung (Übernahme in andere Webseite) unserer Webseiten, des Layouts der Webseiten, ihrer Inhalte (Texte, Bilder, Programme) ganz oder teilweise, in veränderter oder unveränderter Form ist nur nach vorheriger schriftlicher Zustimmung zulässig. Lediglich die nicht-kommerzielle private Nutzung ist in den Grenzen des Urheberrechtsgesetzes zulässig.
              <br /><br />
              Insbesondere sind alle Bilder, Materialien und Logos, einschließlich des Binance-Logos, urheberrechtlich und markenrechtlich geschützt. Eine Nutzung dieser Materialien ist nur mit ausdrücklicher schriftlicher Genehmigung des Rechteinhabers gestattet.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Haftung für Inhalte / Informationen</h2>
            <p className="text-gray-600">
              Der Inhaber der Seite sowie Dritte, von denen der Inhaber der Seite Informationen auf der Webseite bereitstellt, übernehmen trotz sorgfältiger Beschaffung und Bereitstellung keine Haftung für die Richtigkeit, Vollständigkeit, Aktualität oder Genauigkeit sowie Verfügbarkeit der im Rahmen dieses Informationsangebotes zum Abruf bereitgehaltenen und angezeigten Börsen- und Wirtschaftsinformationen, Kurse, Indizes, Preise, allgemeinen Markdaten und sonstigen zugänglichen Inhalte. Der Inhaber der Seite hat nicht alle Informationen, auf die sich die Dokumente stützen, selbst verifiziert. Der Inhaber der Seite übernimmt keine Haftung für Verluste, die durch die Verteilung/Verwendung dieser Dokumente/Informationen verursacht oder mit der Verteilung/Nutzung dieser Dokumente/Informationen im Zusammenhang stehen. Dies gilt insbesondere für die Preisangaben auf der Webseite, deren Richtigkeit nicht garantiert werden kann.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Beratung / Anlageempfehlung</h2>
            <p className="text-gray-600">
              Die Veröffentlichung stellt keine Anlageberatung dar und daher auch keine Empfehlung zum Kauf bzw. zum Verkauf eines Wertpapiers, eines Terminkontraktes oder eines sonstigen Finanzinstrumentes. Die hier bereitgestellten Dokumente/Informationen sind ausschließlich zur Information bestimmt und können ein individuelles Beratungsgespräch nicht ersetzen. Eine Investitionsentscheidung bezüglich irgendwelcher Wertpapiere, Terminkontrakte oder sonstiger Finanzinstrumente sollte auf Grundlage eines Beratungsgesprächs erfolgen und auf keinen Fall auf der Grundlage dieser Dokumente/Informationen. Die Dokumente/Informationen können durch aktuelle Entwicklungen überholt sein, ohne dass die bereitgestellten Dokumente/Informationen geändert wurden.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Angebot</h2>
            <p className="text-gray-600">
              Die Einstellung dieser Dokumente/Informationen ins Internet stellt kein Angebot zum Erwerb von Wertpapieren, Terminkontrakten oder sonstigen Finanzinstrumenten dar.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Links</h2>
            <p className="text-gray-600">
              Links auf Unterseiten und Unterverzeichnisse (Deep-Links, Img-Links) sind nur nach vorheriger Zustimmung des Inhabers der Seite zulässig. Auf die Webseite des Inhabers der Seite kann dagegen ohne gesonderte Zustimmung in einem angemessenen Kontext verlinkt werden. Den Inhalt fremder Webseiten, auf die aus den Webseiten des Inhabers der Seite heraus verwiesen wird, macht sich der Inhaber der Seite nicht zu eigen. Es kann keine Verantwortung für den Inhalt der verlinkten Seiten übernommen werden, insbesondere nicht für Änderungen der Seiten, die nach dem Setzen des Links erfolgt sind.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Markenzeichen</h2>
            <p className="text-gray-600">
              Die folgenden Namen oder Bezeichnungen sind eingetragene Marken der Deutsche Börse AG: Deutsche Börse, FWB, Neuer Markt (Wort-Bildmarke), SMAX, European Energy Exchange EEX, Eurex, Xetra, DAX, NEMAX, MDAX, SDAX, CDAX, HDAX, VDAX, JEX, REX, PEX, Xelsius, XTF Exchange Traded Funds, ExServes, TRICE, Vision&Money, SCHATZ-FUTURE, xlaunch, Newex, Newex New European Exchange. Alle anderen hier erwähnten Produktbezeichnungen dienen lediglich Identifikationszwecken und können Marken bzw. eingetragene Marken der jeweiligen Unternehmen sein. Die Dow Jones IndexesSM werden von der Dow Jones & Company, Inc. zusammengestellt, errechnet und vertrieben; ihre Verwendung ist lizenzpflichtig. Der gesamte Inhalt der Dow Jones IndexesSM ist urheberrechtlich geschützt: © 2003 Dow Jones & Company, Inc. CME Marktdaten und S&P Indizes sind Eigentum der Chicago Mercantile Exchange sowie ihrer Lizenzgeber, welche sich alle Rechte vorbehalten.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Rechtswahl und Gerichtsstand</h2>
            <p className="text-gray-600">
              Die Bedingungen für die Webseiten des Inhabers der Seite unterliegen deutschem Recht. Gerichtsstand ist Frankfurt am Main.
            </p>
          </section>
        </article>
      </div>
    </div>
  );
};

export default Disclaimer;
