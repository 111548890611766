import React from 'react';
import { Link } from 'react-router-dom'; // Falls Sie React Router verwenden


const Footer = () => {
  return (
    <footer className="bg-gray-800 text-center py-4">
      <h1 className="font-poppins font-semibold text-white ">WIE KAUFT MAN BITCOIN?</h1>
      <Link to="/impressum" className="mr-2 underline text-lg text-white font-semibold hover:text-blue-400">Impressum</Link>
      <Link to="/disclaimer" className="text-lg underline text-white font-semibold hover:text-blue-400">Disclaimer</Link>

    </footer>
  );
};

export default Footer;
