import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Hero from "./components/hero";
import PriceMarquee from './components/pricemarquee';
import Navbar from "./components/navbar";
import Blog from "./pages/blog";
import Footer from "./components/footer";
import Article from "./components/anleitung";
import Impressum from "./pages/impressum";
import Disclaimer from './pages/disclamier';
import BitcoinETFArticle from './pages/post_etf';

import Posts from "./components/posts"

function App() {
  return (
    <Router>
      <PriceMarquee />
      <Navbar />
      <Hero />
      
      <Routes>
        <Route path="/blog" element={<Posts />} />        
        <Route path="/" element={<Article />} />
        <Route path="/home" element={<Home />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
        
        <Route path="/post/:id" element={<Article />} /> {/* Neue Route für Artikel */}


        {/* Fügen Sie hier weitere Routen hinzu, falls nötig */}
      </Routes>
    </Router>
  );
}

// Home-Komponente als Wrapper für Article und Hero
const Home = () => {
  return (
    <div className="background">
      <Article />
      <Footer />
    </div>
  );
}

export default App;
