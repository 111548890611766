import React from "react";
import "./Blog.css";
import Post from "./post";

const Posts = () => {
    const blogPosts = [
        {
            title: "Was ist Bitcoin?",
            body: `Bitcoin ist eine dezentrale digitale Währung, die 2008 von einer unbekannten Person oder Gruppe von Personen unter dem Namen Satoshi Nakamoto eingeführt wurde. Es handelt sich um ein Peer-to-Peer-System, das Transaktionen ohne die Notwendigkeit einer zentralen Behörde ermöglicht.`,
            author: "Satoshi Nakamoto",
            imgUrl: "https://example.com/images/bitcoin_intro.jpg",
        },
        {
            title:"Bitcoin ETF - Geht der Preis Richtung Mond?",
            body:""
        }

    ];
    

    return (
        <div className="posts-container">
            {blogPosts.map((post, index) => (
                <Post key={index} index={index} post={post} />
            ))}
        </div>
    );
};

export default Posts;
