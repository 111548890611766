import React from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

const Post = ({ post, index }) => {
    return (
        <div className="post-container">
            <div className="image-container">
                <img src={post.imgUrl} alt={post.title} className="image" />
            </div>
            <div className="content">
                <h2 className="title">{post.title}</h2>
                <p className="body">{post.body}</p>
                <p className="author">By {post.author}</p>
                <Link to={`/post/${index}`} className="read-more">Weiterlesen</Link>
            </div>
        </div>
    );
};

export default Post;
