import React from 'react';
const herojs = () => {


return (
    <div className="bg-gray-900 text-center h-15">
    <h1 className="pt-12 font-poppins font-semibold text-white text-mbHeading">WIE KAUFT MAN ONLINE BITCOIN?
    </h1>
    <div className='flex justify-center mt-9'>
    
    </div>
    </div>
  );
};

export default herojs;



